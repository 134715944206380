import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { companyGetByUUIDRequest, quizCreateRequest } from 'hm-redux/actions/hmActions';
import history from '../../../utils/history';
import Dashboard from '../dashboard/dashboard';
import Finish from '../finish/finish';
import Form from '../form/form';
import Welcome from '../welcome/welcome';
import Language from '../lenguage/language';
import Credit from 'hm-components/dashboard/credit';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class Quiz extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            step: 10,
            information: {}
        };
    }

    onChangeStep = (step: number) => {
        this.setState({step});
    }

    onSetInformation = (information) => {
        this.setState({
            information
        })
    }

    onFinish = (step, alternatives) => {
        const { executeRecaptcha } = this.props.googleReCaptchaProps;
        if (!executeRecaptcha) {
            console.log('Recaptcha has not been loaded');
            return;
        }
        executeRecaptcha('homepage').then((token) => {
            this.props.quizCreateRequest({
                role: this.state.information.role,
                country: this.state.information.country,
                site: this.state.information.site,
                company: this.props.hmReducers.onlyOne.name,
                response: alternatives
            }, token, () => {
                this.setState({
                    step,
                    alternatives
                });
            })
        }).catch((err) => {
            console.log(err);
        });
    }

    componentDidMount() {
        const params = new URLSearchParams(history.location.search)
        if(params.get("quiz"))
            this.props.companyGetByUUIDRequest(params.get("quiz"), (company: any) => {
                if(company === undefined || company === null) {
                    this.setState({
                        step: 0
                    })
                }
            })
        else {
            this.setState({
                step: 0
            })
        }
    }

    render() {

        const { step, information } = this.state;
        const { onlyOne, t } = this.props.hmReducers;

        return (
            <>
                {
                    step === 0 &&
                    <p>Not Valid Company</p>
                }
                {
                    step === 1 &&
                    <Welcome onChangeStep={this.onChangeStep} onlyOne={onlyOne}/>
                }
                {
                    step === 2 &&
                    <Dashboard onChangeStep={this.onChangeStep} onSetInformation={this.onSetInformation} onlyOne={onlyOne}/>
                }
                {
                    step === 3 &&
                    <Form onFinish={this.onFinish} information={information} onlyOne={onlyOne}/>
                }
                {
                    step === 4 &&
                    <Finish onChangeStep={this.onChangeStep} onlyOne={onlyOne}/>
                }
                {
                    step === 10 &&
                    <Language onChangeStep={this.onChangeStep} onlyOne={onlyOne}/>
                }
                {
                    step !== 3 &&
                    <Credit/>
                }
            </>
        )
    }
}

const reducer = 'hmReducers';

const mapStateToProps = (state: any) => ({
    hmReducers: state.get(reducer)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        companyGetByUUIDRequest,
        quizCreateRequest,
    }, dispatch);
};  

export default connect(mapStateToProps, mapDispatchToProps)(withGoogleReCaptcha(Quiz));