import React from 'react';
import './language.scss';
import intense from '../../../assets/images/intense.png';
import { withTranslation } from 'react-i18next';
import company from '../../../assets/images/company.png';
import { Badge, Input, MenuItem, Modal, Paper, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import arrow from '../../../assets/images/arrow.png';
import arrowBlack from '../../../assets/images/arrow-black.png';
import questions from '../../../questions.json';
import SimpleModal from 'hm-components/base/simpleModal';
import okImage from '../../../assets/images/ok.png';
import UkImage from '../../../assets/images/banderas-01.svg';
import GeImage from '../../../assets/images/banderas-02.svg';
import HoImage from '../../../assets/images/banderas-04.svg';
import FrImage from '../../../assets/images/banderas-03.svg';
import PtImage from '../../../assets/images/banderas-05.svg';
import ItImage from '../../../assets/images/banderas-06.svg';
import EnImage from '../../../assets/images/banderas-07.svg';

class Language extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            activeLanguage: 1
        }
    }

    render() {
        const { onlyOne, i18n, t } = this.props;
        const { activeLanguage } = this.state;

        return (
            <>
                <div className="language__header">
                    <div className="left__welcome">
                        <div className="left__welcome__header">
                            <img style={{width: '12.5rem'}} src={intense}/>
                        </div>
                    </div>
                    <div className="right__welcome">
                        <div className="right__welcome__header">
                            {/* <img style={{width: '12.5rem'}} src={company}/> */}
                            {/* <img style={{height: '6rem'}} src={onlyOne.logo}/> */}
                        </div>
                    </div>
                </div>
                <div className="language__body">
                    <div className="language__body__container">
                        <img style={{height: '10rem'}} src={onlyOne.logo}/>
                        <div className="text">
                            {"Choose your language  /  Wähle deine Sprache  /  Kies je taal  /  Choisissez votre langue / Escolha o seu idioma / Scegli la tua lingua".toUpperCase()}
                        </div>
                        <div className="countries">
                            <div className={activeLanguage === 1 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 1}); i18n.changeLanguage('en') }}>
                                <UkImage/>
                            </div>
                            <div className={activeLanguage === 2 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 2}); i18n.changeLanguage('ge') }}>
                                <GeImage/>
                            </div>
                            <div className={activeLanguage === 3 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 3}); i18n.changeLanguage('du') }}>
                                <HoImage/>
                            </div>
                            <div className={activeLanguage === 4 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 4}); i18n.changeLanguage('fr') }}>
                                <FrImage/>
                            </div>
                            <div className={activeLanguage === 5 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 5}); i18n.changeLanguage('pt') }}>
                                <PtImage/>
                            </div>
                            <div className={activeLanguage === 6 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 6}); i18n.changeLanguage('it') }}>
                                <ItImage/>
                            </div>
                            <div className={activeLanguage === 6 ? 'active' : ''} onClick={() => { this.setState({activeLanguage: 6}); i18n.changeLanguage('en') }}>
                                <EnImage/>
                            </div>
                        </div>
                        <input type="button" value={t('welcome.button').toUpperCase()} onClick={() => this.props.onChangeStep(1)}/>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Language);