import React from 'react';
import './finish.scss';
import intense from '../../../assets/images/intense.png';
import company from '../../../assets/images/company.png';
import { Badge, Input, MenuItem, Modal, Paper, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import arrow from '../../../assets/images/arrow.png';
import arrowBlack from '../../../assets/images/arrow-black.png';
import questions from '../../../questions.json';
import SimpleModal from 'hm-components/base/simpleModal';
import okImage from '../../../assets/images/ok.png';

class Finish extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            activeAlternative: {},
            questions: [],
            activeQuestion: '',
            modalNotSelect: false,
            finish: false,
            finishActive: ''
        }
    }

    shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
    }

    convertJsonToArray = () =>{
        const keys = Object.keys(questions);
        const temp = [];
        for (const key of keys) {
            temp.push({code: key, statement: questions[key].statement, alternatives: questions[key].alternatives})
        }
        return temp;
    }

    onSelectAlternative = (value) => {
        const activeAlternative = this.state;
        (activeAlternative as any)[this.state.activeQuestion] = value;
        this.setState({
            activeAlternative
        });
    }

    onReSelectAlternative = (value) => {
        if(!this.state.editQuestion) return;
        const activeAlternative = this.state;
        (activeAlternative as any)[this.state.finishActive] = value;
        this.setState({
            activeAlternative,
            editQuestion: false
        });
    }

    onBack = () => {
        const index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);
        if(index !== 0)
            this.setState({
                activeQuestion: this.state.questions[index - 1].code
            });
    }

    onNext = () => {
        const { activeAlternative } = this.state;
        if(activeAlternative[this.state.activeQuestion] === undefined) {
            this.setState({
                modalNotSelect: true
            })
        } else {
            const index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);
            if(index + 1< this.state.questions.length)
                this.setState({
                    activeQuestion: this.state.questions[index + 1].code
                });
            else 
                this.setState({
                    finish: true
                });
        }
    }

    componentDidMount() {
        let tmp = [];
        tmp = this.convertJsonToArray().slice(0, 9);// this.shuffle(this.convertJsonToArray().slice(0, 9));
        this.setState({
            questions: tmp,
            activeQuestion: tmp[0].code
        });
    }

    render() {
        const { onlyOne, t } = this.props;
        const { activeAlternative, activeQuestion, questions, modalNotSelect, finish, finishActive } = this.state;
        const question = this.state.questions.find((x) => x.code === activeQuestion) || { statement: '', alternatives: [] };
        const reviewQuestion = this.state.questions.find((x) => x.code === finishActive) || { statement: '', alternatives: [] };
        let index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);

        if(finish) index += 1;

        return (
            <>
                <div className="finish__header">
                    <div className="left__welcome">
                        <div className="left__welcome__header">
                            <img style={{width: '12.5rem'}} src={intense}/>
                        </div>
                    </div>
                    <div className="right__welcome">
                        <div className="right__welcome__header">
                            {/* <img style={{width: '12.5rem'}} src={company}/> */}
                            <img style={{height: '6rem'}} src={onlyOne.logo}/>
                        </div>
                    </div>
                </div>
                <div className="finish__body">
                    <div className="finish__body__container">
                        <img src={okImage} style={{width: '10.625rem'}} height="auto"/>
                        <div className="text">
                            {t('finish.title')}<br/><br/>
                            {t('finish.body1')}<br/><br/>
                            {t('finish.body2')}<br/><br/>
                            {t('finish.body3')}
                        </div>
                        <input type="button" value={t('finish.button').toUpperCase()} onClick={() => window.location.href = 'https://www.in-tense.nl'}/>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Finish);