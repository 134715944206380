import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import Dashboard from '../templates/dashboard';
import { Quiz } from '../pages/pageListAsync';
// import { PrivateRoute } from 'hm-components/base/privateRoute';
import Welcome from 'containers/pages/welcome/welcome';

class Application extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" component={Quiz}/>
      </Switch>
    );
  }
}

export default Application;