import { Badge, Paper } from '@material-ui/core';
import React from 'react';

interface QuestionProps {
    active: number
    onSelectAlternative: any
    title: string
    alternatives: []
    t: any
}

const Question = (props: QuestionProps) => (
    <>
        <div className="question">{props.t(`form.statements.${props.title}`).toUpperCase()}</div>
        <div className="grid-container" style={{gridTemplateColumns: 'repeat(auto-fill, 19rem)'}}>
            {
                props.alternatives.map((item: any, i) => 
                    <div className="grid-item" key={i}>
                        <Badge key={i} anchorOrigin={{vertical: 'top', horizontal: 'left'}} badgeContent={props.active === item.code ? <i className='fas fa-check'></i> : String.fromCharCode(i+65) + '.'}>
                            <Paper className={props.active === item.code ? 'question__container active' : props.active !== null ? 'question__container inactive' : 'question__container'} elevation={0} onClick={() => props.onSelectAlternative(item.code)}>
                                { props.t(`form.alternatives.${item.text}`) }
                            </Paper>
                        </Badge>
                    </div>
                )
            }
        </div>
    </>
)

export default Question;