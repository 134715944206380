import {
    COMPANY_GET_BY_UUID_REQUEST,
    COMPANY_GET_BY_UUID_REQUEST_FAILED,
    COMPANY_GET_BY_UUID_REQUEST_SUCCESS,
    QUIZ_CREATE_REQUEST,
    QUIZ_CREATE_REQUEST_FAILED,
    QUIZ_CREATE_REQUEST_SUCCESS
} from '../constants/hmConstants';

const initialState: any = {
    loggedIn: false,
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || null,
    tables: {
        menu:[
            {
                "moducode":"COMPA",
                "moduname":"Company",
                "menuicon":"fas fa-building",
                "menuurls":"/admin/company"
            }
        ],
        region: []
    },
    onlyOne: {
        id: '',
        branch: [],
        logo: '',
        name: '',
        region: []
    },
    list: [],
    total: 0,
};

export default function authReducer(state = initialState, action: any = {}) {
    switch(action.type) { 
        case COMPANY_GET_BY_UUID_REQUEST:
        case QUIZ_CREATE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case COMPANY_GET_BY_UUID_REQUEST_SUCCESS:
            return {
                ...state,
                tables: { ...state.tables, ...action.tables },
                onlyOne: action.onlyOne,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case QUIZ_CREATE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                failed: false,
                failedTitle: null,
                failedContent: null
            };
        case COMPANY_GET_BY_UUID_REQUEST_FAILED:
        case QUIZ_CREATE_REQUEST_FAILED:
            return {
                ...initialState,
                loading: false,
                failed: true,
                failedTitle:  action.titleError,
                failedContent:  action.titleContent,
            };
        default:
            return state;
    }
}