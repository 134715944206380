import React from 'react';
import logo from '../../assets/images/magia.png';
import {createUseStyles} from 'react-jss'

const useStyles = createUseStyles({
    container: {
        'position': 'absolute',
        'right': '0',
        'margin': '0.75rem 10rem',
        'color': '#2f2944',
        'fontSize': '1rem',
        'bottom': 0,
        'fontFamily': 'HelveticaNeue Roman',
        'display': 'flex',
        'alignItems': 'center'
    },
    logo: {
        'height': '1rem',
        'width': 'auto'
    }
})

interface Credit {
    // title: string
    // content: string
}

const Credit = (props: Credit) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div>
                Powered By&nbsp;
            </div>
            <img className={classes.logo} src={logo}/>
        </div>
    )
}

export default Credit;