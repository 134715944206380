import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';
import translationGE from './locales/ge.json';
import translationDU from './locales/du.json';
import translationPT from './locales/pt.json';
import translationIT from './locales/it.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  ge: {
    translation: translationGE
  },
  du: {
    translation: translationDU
  },
  pt: {
    translation: translationPT
  },
  it: {
    translation: translationIT
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    //keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;