import React from 'react';
import './form.scss';
import intense from '../../../assets/images/intense.png';
import company from '../../../assets/images/company.png';
import { Badge, FormControl, Input, MenuItem, Modal, Paper, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import arrow from '../../../assets/images/arrow.png';
import arrowBlack from '../../../assets/images/arrow-black.png';
import questions from '../../../questions.json';
import Question from './question';
import SimpleModal from 'hm-components/base/simpleModal';
import rankingImage from '../../../assets/images/ranking.png';

class Form extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            activeAlternative: {},
            questions: [],
            activeQuestion: '',
            modalNotSelect: false,
            finish: false,
            finishActive: ''
        }
    }

    shuffle = (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      
        while (0 !== currentIndex) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
      
        return array;
    }

    convertJsonToArray = () =>{
        const keys = Object.keys(questions);
        const temp = [];
        for (const key of keys) {
            const alternatives = this.shuffle(questions[key].alternatives.map((item, i) => {return {code: i+1,text:item}}));
            temp.push({code: key, statement: questions[key].statement, alternatives: alternatives, role: questions[key].role})
        }
        return temp;
    }

    onSelectAlternative = (value) => {
        const { activeAlternative } = this.state;
        (activeAlternative as any)[this.state.activeQuestion] = value;
        this.setState({
            activeAlternative
        });
    }

    onReSelectAlternative = (value) => {
        if(!this.state.editQuestion) return;
        const { activeAlternative } = this.state;
        (activeAlternative as any)[this.state.finishActive] = value;
        this.setState({
            activeAlternative,
            editQuestion: false
        });
    }

    onBack = () => {
        const index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);
        if(index !== 0)
            this.setState({
                activeQuestion: this.state.questions[index - 1].code
            });
    }

    onNext = () => {
        const { activeAlternative } = this.state;
        if(activeAlternative[this.state.activeQuestion] === undefined) {
            this.setState({
                modalNotSelect: true
            })
        } else {
            const index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);
            if(index + 1< this.state.questions.length)
                this.setState({
                    activeQuestion: this.state.questions[index + 1].code
                });
            else 
                this.setState({
                    finish: true
                });
        }
    }

    componentDidMount() {
        let tmp = [];
        const role = this.props.information.role === '01' ? '01' : '02';
        tmp = this.convertJsonToArray().filter((x) => x.role === role);// this.shuffle(this.convertJsonToArray().slice(0, 9));
        this.setState({
            questions: tmp,
            activeQuestion: tmp[0].code
        });
    }

    render() {
        const { onlyOne, t } = this.props;
        const { activeAlternative, activeQuestion, questions, modalNotSelect, finish, finishActive } = this.state;
        const question = this.state.questions.find((x) => x.code === activeQuestion) || { statement: '', alternatives: [] };
        const reviewQuestion = this.state.questions.find((x) => x.code === finishActive) || { statement: '', alternatives: [] };
        let index = this.state.questions.findIndex((x) => x.code === this.state.activeQuestion);

        if(finish) index += 1;

        return (
            <div className="form">
                <div className="left__welcome">
                    <div className="left__welcome__header">
                        <img style={{width: '12.5rem'}} src={intense}/>
                    </div>
                    <div className="left__welcome__body">
                        <div>
                            <div className="left__title"><img src={arrowBlack}/><p>{t('form.title').toUpperCase()}</p></div>
                            <div className="main__title">
                                <div className="line"></div>
                                <Stepper activeStep={index} orientation="vertical">
                                    {
                                        questions.map((item, i) => 
                                            <Step>
                                                {
                                                    i <= index ?
                                                    <StepLabel className={finish ? finishActive === item.code ? 'step__navigator active' : 'step__navigator' : ''} onClick={() => { if(finish) this.setState({finishActive: item.code}) } }>
                                                        {t(`form.statements.${item.statement}`).toUpperCase()}
                                                    </StepLabel>
                                                    :
                                                    <StepLabel></StepLabel>
                                                }
                                            </Step>
                                        )
                                    }
                                </Stepper>
                            </div>
                        </div>
                    </div>
                    <div className="left__welcome__footer">
                    </div>
                </div>
                <div className="right__welcome">
                    <div className="right__welcome__header">
                        <img style={{height: '6rem'}} src={onlyOne.logo}/>
                        {/* <img style={{width: '12.5rem'}} src={company}/> */}
                    </div>
                    <div className="right__welcome__body">
                        {
                            !finish ?
                            <Question active={activeAlternative[this.state.activeQuestion]} title={question.statement} alternatives={question.alternatives} onSelectAlternative={this.onSelectAlternative} t={t}/>
                            :
                            <>
                                {
                                    finishActive === '' ?
                                    <div style={{padding: '6.25rem', textAlign: 'center'}}>
                                        <img src={rankingImage} style={{width: '9.375rem'}} height="auto"/>
                                        <br/>
                                        <br/>
                                        <div className="question">
                                            {t('form.finish').toUpperCase()}
                                        </div>
                                    </div>
                                    :
                                    <Question active={activeAlternative[this.state.finishActive]} title={reviewQuestion.statement} alternatives={reviewQuestion.alternatives} onSelectAlternative={this.onReSelectAlternative} t={t}/>
                                }
                            </>
                        }
                    </div>
                    <div className="right__welcome__footer">
                        <div className="grid-container" style={{gridTemplateColumns: 'auto auto'}}>
                            <div className="grid-item">
                                {
                                    !finish && index !== 0?
                                    <input type="button" className="back" value={t('form.back').toUpperCase()} onClick={this.onBack}/>
                                    : 
                                    <>
                                        {
                                            finishActive !== '' &&
                                            <input type="button" className="edit" value={ this.state.editQuestion ? t('form.editing').toUpperCase() : t('form.change').toUpperCase() } onClick={() => this.setState({editQuestion: true}) }/>
                                        }
                                    </>
                                }
                            </div>
                            <div className="grid-item" style={{textAlign: 'end'}}>
                                {
                                    !finish ?
                                    <input type="button" className="next" value={t('form.next').toUpperCase()} onClick={this.onNext}/>
                                    :
                                    <>
                                        {
                                            finishActive === '' ?
                                            <input type="button" value={t('form.finish_button').toUpperCase()} onClick={() => { this.props.onFinish(4, activeAlternative);  }}/>
                                            :
                                            <input type="button" className="end" value={t('form.goto').toUpperCase()} onClick={() => this.setState({finishActive: ''}) }/>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <SimpleModal open={modalNotSelect} handleClose={() => this.setState({modalNotSelect: false})} handleOk={() => this.setState({modalNotSelect: false})} t={t}/>
                {/* <Modal
                    open={modalNotSelect}
                    onClose={() => {}}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div>holis</div>
                </Modal> */}
            </div>
        )
    }
}

export default withTranslation()(Form);