import { hmModels } from 'hm-models';
import * as types from '../constants/hmConstants';

export const companyGetByUUIDRequest = (uuid: string, callback: any) => ({
    type: types.COMPANY_GET_BY_UUID_REQUEST,
    uuid,
    callback
});

export const companyGetByUUIDRequestSuccess = (onlyOne: Array<any>, tables: any) => ({
    type: types.COMPANY_GET_BY_UUID_REQUEST_SUCCESS,
    onlyOne,
    tables
});

export const companyGetByUUIDRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.COMPANY_GET_BY_UUID_REQUEST_FAILED,
    titleError,
    titleContent
});

export const quizCreateRequest = (body: any, token: string, callback: any) => ({
    type: types.QUIZ_CREATE_REQUEST,
    body,
    token,
    callback
});

export const quizCreateRequestSuccess = () => ({
    type: types.QUIZ_CREATE_REQUEST_SUCCESS
});

export const quizCreateRequestFailed = (titleError: string, titleContent: string) => ({
    type: types.QUIZ_CREATE_REQUEST_FAILED,
    titleError,
    titleContent
});