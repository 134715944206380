import React from 'react';
import './dashboard.scss';
import intense from '../../../assets/images/intense.png';
import company from '../../../assets/images/company.png';
import { FormControl, Input, MenuItem, Popper, Select, TextField } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import InfoIcon from '../../../assets/images/information.svg';
class Dashboard extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            role: '00',
            country: '00',
            site: '00',
            anchorEl: null
        }
    }

    onStart = () => {
        const { role, country, site } = this.state;
        if(role !== '00' && country !== '00' && site !== '00') {
            this.props.onChangeStep(3)
            this.props.onSetInformation({...this.state})
        }
    }

    render() {
        const { role, country, site } = this.state;
        const { onlyOne, t } = this.props;
        return (
            <div className="dashboard">
                <div className="left__welcome">
                    <div className="left__welcome__header">
                        <img style={{width: '12.5rem'}} src={intense}/>
                    </div>
                    <div className="left__welcome__body">
                        <div>{t('dashboard.title1')}</div>
                        <div>{t('dashboard.title2')}<span>......</span></div>
                    </div>
                    <div className="left__welcome__footer">
                    </div>
                </div>
                <div className="right__welcome">
                    <div className="right__welcome__header">
                        <img style={{height: '6rem'}} src={onlyOne.logo}/>
                    </div>
                    <div className="right__welcome__body">
                        <div style={{height: '1.875rem'}}></div>
                        <div className="grid-container" style={{gridTemplateColumns: '10rem auto'}}>
                            <div className="grid-item">{t('dashboard.fields.company').toUpperCase()}:</div>
                            <div className="grid-item"><TextField disabled fullWidth id="name" variant="outlined" value={onlyOne.name}/></div>
                        </div>
                        <div className="grid-container" style={{gridTemplateColumns: '10rem auto', position: 'relative'}}>
                            <div className="grid-item">{t('dashboard.fields.role').toUpperCase()}:</div>
                            <div className="grid-item info">
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="acpemont"
                                        className="select"
                                        fullWidth
                                        defaultValue={'00'}
                                        value={role}
                                        onChange={(e) => this.setState({role: e.target.value})}
                                    >
                                        <MenuItem value="00" disabled>Place select</MenuItem>
                                        <MenuItem value="01">{t('dashboard.options.management')}</MenuItem>
                                        <MenuItem value="02">{t('dashboard.options.supervisor')}</MenuItem>
                                        <MenuItem value="03">{t('dashboard.options.shopfloor')} </MenuItem>
                                    </Select>
                                </FormControl>
                                <div className="svg_container" tabIndex={1} onFocus={(e) => {  console.log(e.currentTarget); this.setState({anchorEl: e.currentTarget}) }} onBlur={() => this.setState({anchorEl: null})}>
                                    <InfoIcon/>
                                </div>
                            </div>
                        </div>
                        <div className="grid-container" style={{gridTemplateColumns: '10rem auto'}}>
                            <div className="grid-item">{t('dashboard.fields.country').toUpperCase()}:</div>
                            <div className="grid-item">
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="acpemont"
                                        className="select"
                                        fullWidth
                                        defaultValue={'00'}
                                        value={country}
                                        onChange={(e) => this.setState({country: e.target.value})}
                                    >
                                        <MenuItem value="00" disabled>Place select</MenuItem>
                                        {
                                            onlyOne.region.map((item, i) =>
                                                <MenuItem key={i} value={item.code}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="grid-container" style={{gridTemplateColumns: '10rem auto'}}>
                            <div className="grid-item">{t('dashboard.fields.site').toUpperCase()}:</div>
                            <div className="grid-item">
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="acpemont"
                                        className="select"
                                        fullWidth
                                        defaultValue={'00'}
                                        value={site}
                                        onChange={(e) => this.setState({site: e.target.value})}
                                    >
                                        <MenuItem value="00" disabled>Place select</MenuItem>
                                        {
                                            onlyOne.branch.filter((x) => x.codeRegion === country).map((item, i) =>
                                                <MenuItem key={i} value={item.name}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="right__welcome__footer">
                        <input type="button" value={t('dashboard.button').toUpperCase()} onClick={this.onStart}/>
                    </div>
                </div>
                <Popper open={this.state.anchorEl ? true : false} anchorEl={this.state.anchorEl} placement={'right'} transition>
                    <div className="popover__info">
                        <div>{t('dashboard.options.management')}: {t('dashboard.box.management')}</div>
                        <div>{t('dashboard.options.supervisor')}: {t('dashboard.box.supervisor')}</div>
                        <div>{t('dashboard.options.shopfloor')}: {t('dashboard.box.shopfloor')}</div>
                    </div>
                </Popper>
            </div>
        )
    }
}

export default withTranslation()(Dashboard);