/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from '../utils/history';
import hmReducers from 'redux/reducers/hmReducers';

// function branchReducer(reducerFunction, reducerName) {
//   return (state, action) => {
//     const { branch } = action;
//     const isInitializationCall = state === undefined;
//     if (branch !== reducerName && !isInitializationCall) {
//       return state;
//     }
//     return reducerFunction(state, action);
//   };
// }

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    router: connectRouter(history),
    hmReducers,
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}