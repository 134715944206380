import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { PrivateRoute } from 'hm-components/base/privateRoute';
import { PublicRoute } from 'hm-components/base/publicRoute';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Application from './application';
(window as any).__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const theme = createMuiTheme({
  palette: {
    primary: { 
      main: '#0060A6'
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Switch>
          {/* <Route path="/" exact>
            <Redirect to="/quiz"/>
          </Route> */}
          <Route path='/' component={Application}/>
        </Switch>
      </ThemeProvider>
    );
  }
}

export default App;