import React from 'react';
import './welcome.scss';
import intense from '../../../assets/images/intense.png';
import company from '../../../assets/images/company.png';
import arrow from '../../../assets/images/arrow.png';
import Button from 'hm-components/base/button';
import { withTranslation } from 'react-i18next';

// const withTranslation = require('react-i18next').withTranslation;

class Welcome extends React.Component<any> {

    constructor(props) {
        super(props);
    }

    render() {
        const { onlyOne, t } = this.props;

        return (
            <div className="welcome">
                <div className="left__welcome">
                    <div className="left__welcome__header">
                        <img style={{width: '12.5rem'}} src={intense}/>
                    </div>
                    <div className="left__welcome__body">
                        {
                            <img style={{height: '8.125rem'}} src={onlyOne.logo}/>
                        }
                        <div>
                            <div className="left__title"><img src={arrow}/><p>{t('welcome.title')}</p></div>
                            <div className="main__title">
                                <div className="main__title__first">{t('welcome.introduction.title').toUpperCase()}</div>
                                <br/>
                                <div className="main__title__second">{t('welcome.introduction.subtitle')}</div>
                                <br/>
                                <div className="main__title__body">{t('welcome.introduction.body')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="left__welcome__footer">
                        <Button type="primary" size="medium" onClick={(e) => this.props.onChangeStep(2)}>{t('welcome.button').toUpperCase()}</Button>
                    </div>
                </div>
                <div className="right__welcome__header">
                    <div className="left__welcome__header">
                    </div>
                    <div className="left__welcome__body">
                    </div>
                    <div className="left__welcome__footer">
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Welcome);