import {
    call, fork, put, take, takeEvery, all
  } from 'redux-saga/effects';
import history from '../../utils/history';
import { COMPANY_GET_BY_UUID_REQUEST, QUIZ_CREATE_REQUEST } from 'redux/constants/hmConstants';
import pipelines from 'services';
import { companyGetByUUIDRequestFailed, companyGetByUUIDRequestSuccess, quizCreateRequestFailed, quizCreateRequestSuccess } from 'redux/actions/hmActions';

function* companyGetByUUIDSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.companyGetByUUID, action.uuid)).data;
        yield put(companyGetByUUIDRequestSuccess(data.data.onlyOne, data.data.tables));
        yield call(action.callback, data.data.onlyOne);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(companyGetByUUIDRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* quizCreateSaga(action: any) {
    try {
        const data = (yield call(pipelines.hmPipeline.quizInsert, action.body, action.token)).data;
        yield put(quizCreateRequestSuccess());
        yield call(action.callback);
    } catch (error) {
        const err = error.response?.data || { code: '0x0', message: error.message || "Error al establecer una conexion con el servidor" };
        yield put(quizCreateRequestFailed(`Error: ${err.code}`, err.message));
    }
}

function* hmRootSaga() {
    yield all([
        takeEvery(COMPANY_GET_BY_UUID_REQUEST, companyGetByUUIDSaga),
        takeEvery(QUIZ_CREATE_REQUEST, quizCreateSaga)
    ]);
}
  
const hmSagas = [
    fork(hmRootSaga),
];
  
export default hmSagas;