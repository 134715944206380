import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import history from './utils/history';

import App from './containers/app';

import configureStore from './redux/configureStore';

import 'index.css';
import './setup.ts';
import './i18n';

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

ReactDOM.render(
    <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey="6LciFfEbAAAAADWbBH9eAnFrlY1ibaYr0RNNtGKk">
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </GoogleReCaptchaProvider>
    </Provider>, 
    MOUNT_NODE
);