import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import modalLogo from '../../assets/images/circle-warning.png';
import Button from './button';
import xImage from '../../assets/images/x.png';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {
  const top = 50; //+ rand();
  const left = 50; //+ rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        position: 'absolute',
        width: '43.75rem',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '0.938rem',
        padding: '8.75rem 3.75rem 1.875rem',
        fontFamily: 'HelveticaNeue Roman',
        fontSize: '1.125rem',
        textAlign: 'center',
        color: '#575757',
        lineHeight: 1.5,
        '&::before': {
            background: `url(${modalLogo}) no-repeat center`,
            backgroundSize: '100%',
            backgroundColor: '#fff',
            content: "''",
            width: '12.5rem',
            height: '12.5rem',
            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
            borderRadius: '12.5rem 12.5rem 0 0',
            borderBottom: '0.188rem solid white',
            top: '-5rem'
        }
    },
    button: {
        backgroundColor: 'white',
        border: '0.063rem solid #ee762f',
        color: '#ee762f',
        padding: '0.625rem 6.25rem',
        fontSize: '1.063rem',
        fontWeight: 600,
        borderRadius: '0.188rem',
        cursor: 'pointer',
        fontFamily: 'HelveticaNeue Bold',
        marginTop: '3.125rem',
        '&:hover': {
            backgroundColor: '#ee762f',
            color: 'white'
        }
    },
    btnClose: {
        top: '-10%',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        position: 'absolute',
        width: '43.75rem',
        textAlign: 'end',
        padding: '0px 1.25rem',
        cursor: 'pointer'
    }
  }),
);

interface SimpleModalProps {
    open: boolean
    t: any
    handleClose: () => void
    handleOk: () => void
}

const SimpleModal = (props: SimpleModalProps) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
        <p id="simple-modal-description">
            {props.t('form.modal')}
        </p>
        <div className={classes.btnClose} onClick={props.handleClose}>
            <img src={xImage} style={{width: '1.25rem', height: '1.25rem'}}/>
        </div>
        <Button className={classes.button} type="primary" size="medium" onClick={props.handleOk}>OK</Button>
    </div>
  );

  return (
    <>
      <Modal
        open={props.open}
        // onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
            {body}
        </>
      </Modal>
    </>
  );
}

export default SimpleModal;