import { hmModels } from 'hm-models';
import { 
    COMPANY_GET_BY_UUID, QUIZ_INSERT, 
} from '../constants/hmConstants'
import request from './basePipeline';

export const companyGetByUUID = (uuid: string) => {
    return request.getWithToken(COMPANY_GET_BY_UUID + uuid);
}

export const quizInsert = (body: any, token: string) => {
    return request.postWithToken(QUIZ_INSERT + token, body);
}